// SpinnerOverlay.jsx
import React from "react";

const SpinnerOverlay = ({ loading }) => {
 if (!loading) return null;

 return (
  <div className="spin-overlay">
   <div>
    <img src="assets/images/loading.gif" width={150} />
   </div>
  </div>
 );
};

export default SpinnerOverlay;
