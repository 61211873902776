import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import { handleStopAndReset, playExplainer } from "../utils/helpers";
import "../pages/styles/style.css";

import AOS from "aos";
import "aos/dist/aos.css";
function About() {
 const [newVideos, setNewVideos] = useState([]);
 const [popularVideos, setPopularVideos] = useState([]);
 const [popularCategories, setPopularCategories] = useState([]);

 const { apiURL, domain } = useContext(Context);
 const exvRef = useRef(null);
 const watchRef = useRef(null);
 const fanRef = useRef(null);
 const aiRef = useRef(null);
 const swapRef = useRef(null);
 const nftRef = useRef(null);

 const watchVid = useRef(null);
 const fanVid = useRef(null);
 const aiVid = useRef(null);
 const swapVid = useRef(null);
 const nftVid = useRef(null);

 const getNewVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_new_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setNewVideos(data);
    console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getNewVideos();
 }, []);

 const getPopularVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_all_popular_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularVideos(data);
    console.log("Alive POPULAR:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularVideos();
 }, []);

 const getPopularCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularCategories();
 }, []);

 useEffect(() => {
  AOS.init({
   duration: 1000,
   once: false,
  });
 }, []);

 const playVideo = (videoRef) => {
  videoRef.current.play();
 };

 const pauseVideo = (videoRef) => {
  videoRef.current.pause();
  videoRef.current.currentTime = 0; // Reset video to start
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div
      className="thim-banner_home-1 ai-home-banner stylish-banner"
      style={{ backgroundImage: "url(assets/images/home.jpg)", backgroundAttachment: "scroll" }}
     >
      <div className="container-fluid">
       <div className="bp-element bp-element-st-list-videos vblog-layout-1">
        <div className="wrap-element">
         <div className="feature-item"></div>
        </div>
       </div>
      </div>
     </div>
     <div className="content container-fluid my-1 p-sm-0 p-md-4 p-lg-5">
      <div className="row d-flex align-items-center" data-aos="fade-right">
       <div className="col-md-6">
        <img src="assets/images/18coin.jpg" width="100%" />
       </div>
       <div className="col-md-6">
        <h1 className="mt-3" style={{ fontSize: "35px", fontWeight: 700 }}>
         ABOUT
        </h1>
        <div className="about my-3">
         <p>
          {" "}
          Τhe <b>Ai18plus</b> is entering the market with a fully developed project, demonstrating
          its preparedness to enhance value within the adult entertainment sector right from the
          outset.
         </p>
        </div>
        <div className="about my-3">
         <p>
          For the first time, we are bringing web 3.0 technologies to the largest revenue-generating
          digital industry, and we are transforming the level of audiovisual experience by
          incorporating services that will “disrupt” the more than 3 billion monthly users -
          visitors of adult sites. The innovations we provide are so numerous and so ...useful that
          it is certain the ai18plus.com platform, which is fully connected to Coin 18+, will gain a
          devoted audience, as you will understand from the detailed description.
         </p>
        </div>
        <div className="about my-3">
         We are creating the first adult digital community where users – viewers and content
         creators – EARN REWARDS for the interactions they generate, in an environment they already
         tend to visit almost daily.
        </div>
        <button
         className="btn btn-custom"
         data-toggle="modal"
         data-target="#intro"
         onClick={() => playExplainer(exvRef)}
        >
         <i className="fa fa-play" aria-hidden="true"></i> WATCH THIS VIDEO TO LEARN MORE
        </button>
       </div>
      </div>
     </div>

     <section
      className="py-5 px-3 overlay-section"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div className="col-12 text-center my-5">
         <h1 className="display-4 text-white" data-aos="fade-right">
          WHY CHOOSE OUR TOKEN?
         </h1>
        </div>
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           INNOVATION & FIRSTMOVER ADVANTAGE
          </h4>
          First Web3 adult entertainment platform leveraging blockchain and AI technology.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           ALL-IN-ONE PLATFORM
          </h4>
          Combines 3 different technologies in 1 platform: 1. AI model creation with prompts and
          tags. 2. Face swap. 3. Photo background removal.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           USER-GENERATED CONTENT
          </h4>
          Allows creation of AIgenerated models and customizable content without real-person ethical
          issues, catering to privacy concerns.
         </div>
        </div>
       </div>
       <div className="content row my-5">
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           NFTid INTEGRATION
          </h4>
          Unique AI models are minted as NFTs, allowing for ownership and trade.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           REDUCED LEGAL RISKS
          </h4>
          AI models mitigate legal issues related to consent and exploitation.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           ADVANCED TECHNOLOGY
          </h4>
          Utilizes Binance Smart Chain for security, scalability and smart contracts for
          transparency and fraud reduction.
         </div>
        </div>
       </div>
       <div className="content row my-5">
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           DECENTRALIZATION
          </h4>
          Combines Web2 and Web3 elements, ensuring data control and a transparent, secure digital
          content economy.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           PRIVACY & ANONYMITY
          </h4>
          Ensures user anonymity and privacy through blockchain technology.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           MONETIZATION OPPORTUNITIES
          </h4>
          Through subscriptions, paid videos and photos, NFTid lending, viewing ads, Business
          Revenue Tokens (BRTs) and content ratings.
         </div>
        </div>
       </div>
      </div>
     </section>

     <div className="content container-fluid mt-5">
      <div className="row d-flex align-items-center" data-aos="fade-left">
       <div className="col-md-2"></div>
       <div className="col-md-8">
        <img src={`${domain}assets/images/tokenomics.png`} width="100%" />
       </div>
       <div className="col-md-2"></div>
      </div>
     </div>

     <div className="container-fluid roadmap-section py-5">
      <h1 className="my-5 text-center display-4" data-aos="fade-right">
       OUR ROADMAP
      </h1>
      <div className="row roadmap-timeline">
       {/* Phase 1 */}
       <div data-aos="fade-right" className="col-md-12 roadmap-phase mb-5 text-center">
        <img src="/assets/images/roadmap.jpg" />
       </div>
      </div>
     </div>

     <section
      className="py-5 px-3 overlay-section"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div className="col-12 text-center my-5">
         <h1 className="display-4 text-white" data-aos="fade-right">
          OUR TEAM
         </h1>
        </div>
        <div data-aos="fade-left" className="col-md-4 text-center">
         40 specialized professors who form the core of the "crypto academy" and over 150 students
         of our crypto academy.
        </div>
        <div data-aos="fade-right" className="col-md-4 text-center">
         Incredible developers who ensure the project's progress, award- winning companies, and
         individuals from the marketing and communication sectors.
        </div>
        <div data-aos="fade-right" className="col-md-4 text-center">
         An exclusive team composed of seasoned professionals from various sectors of the sex
         industry who bring in extensive experience and specialized knowledge, offering unparalleled
         insights and skills.
        </div>
       </div>
      </div>
     </section>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
   {/*modals*/}
   <div
    className="modal fade"
    id="intro"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(exvRef)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={exvRef}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/intro.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="watch"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(watchVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={watchVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/watch2earn.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="fan"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(fanVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={fanVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/just-fans.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="ai"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(aiVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={aiVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/porn-face.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="swap"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(swapVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={swapVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/face-swap.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="nft"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(nftVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={nftVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/nft.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}

   {/*END MODALS*/}
  </div>
 );
}

export default About;
