import React, { useEffect, useRef, useState } from "react";
//import "./AnimationSection.css";

const images = [
 // Replace with URLs or import local images here
 "https://ai18plus.com/uploads/generated/images/3.png",
 "https://ai18plus.com/uploads/generated/images/60.png",
 "https://ai18plus.com/uploads/generated/images/66.png",
 "https://ai18plus.com/uploads/profile/17271156794.png",
 "https://ai18plus.com/uploads/profile/173140532859.png",
 "https://ai18plus.com/uploads/generated/images/8.png",
 "https://ai18plus.com/uploads/generated/images/44.png",
 "https://ai18plus.com/uploads/generated/images/81.png",
];
const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const AnimationSection = () => {
 const [visibleImages, setVisibleImages] = useState([]);
 const [cursorImages, setCursorImages] = useState([]);
 const sectionRef = useRef(null);
 const mouseMoving = useRef(false);
 const mouseMoveTimer = useRef(null);

 useEffect(() => {
  let visibleIndex = 0;

  const getRandomPosition = () => {
   if (sectionRef.current) {
    const { width, height } = sectionRef.current.getBoundingClientRect();
    const x = Math.random() * (width - 100);
    const y = Math.random() * (height - 100);
    return { x, y };
   }
   return { x: 0, y: 0 };
  };

  const intervalId = setInterval(() => {
   const nextImages = images.slice(visibleIndex, visibleIndex + 3).map((src) => ({
    src,
    id: Math.random(),
    position: getRandomPosition(),
   }));
   setVisibleImages(nextImages);
   visibleIndex = (visibleIndex + 3) % images.length;
  }, 3000);

  return () => clearInterval(intervalId);
 }, []);

 useEffect(() => {
  const handleMouseMove = (e) => {
   if (sectionRef.current) {
    const { left, top, width, height } = sectionRef.current.getBoundingClientRect();
    const xPercent = ((e.clientX - left) / width) * 100;
    const yPercent = ((e.clientY - top) / height) * 100;

    mouseMoving.current = true;

    const shuffledImages = shuffleArray(images.slice(0, 5));
    setCursorImages(
     shuffledImages.map((src, i) => ({
      src,
      x: xPercent + i * 3,
      y: yPercent + i * 3,
      opacity: 0.7 - i * 0.1,
     }))
    );

    if (mouseMoveTimer.current) clearTimeout(mouseMoveTimer.current);
    mouseMoveTimer.current = setTimeout(() => {
     mouseMoving.current = false;
     setCursorImages((prevImages) =>
      prevImages.map((img, i) => ({
       ...img,
       x: xPercent,
       y: yPercent,
       opacity: 1 - i * 0.15,
      }))
     );
    }, 100);
   }
  };

  window.addEventListener("mousemove", handleMouseMove);

  return () => {
   window.removeEventListener("mousemove", handleMouseMove);
   if (mouseMoveTimer.current) clearTimeout(mouseMoveTimer.current);
  };
 }, []);

 return (
  <div
   className="enhanced-animation-section ai-home-banner"
   ref={sectionRef}
   style={{
    backgroundImage: "url(assets/images/home.jpg)",
    backgroundAttachment: "scroll",
    borderBottomLeftRadius: "50% 40px",
    borderBottomRightRadius: "50% 40px",
    overflow: "hidden",
   }}
  >
   <div className="overlay"></div>
   <div className="content">
    <h1>Ready for some private fun & earn</h1>
    <p>Explore the best platform where really sexy content creators meet their fans.</p>
    <a href="/creators" className="btn-custom">
     EXPLORE CREATORS
    </a>
    <p className="d-md-none">&nbsp;</p>
    <a href="/become-creator" className="btn-secondary">
     BECOME A CREATOR
    </a>
   </div>

   {/*visibleImages.map((img) => (
    <img
     key={img.id}
     src={img.src}
     alt="popup-tile"
     className="popup-tile"
     style={{
      animationDelay: `${Math.random() * 1}s`,
      top: `${img.position.y}px`,
      left: `${img.position.x}px`,
     }}
    />
   ))*/}

   {/*cursorImages.map((img, index) => (
    <img
     key={`cursor-${index}`}
     src={img.src}
     alt="cursor-tile"
     className="cursor-tile"
     style={{
      top: `${img.y}%`,
      left: `${img.x}%`,
      opacity: img.opacity,
      zIndex: cursorImages.length - index,
     }}
    />
   ))*/}
  </div>
 );
};
export default AnimationSection;
