import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { handleStopAndReset, playExplainer } from "../../utils/helpers";

import "../../components/three.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function WatchVR() {
  const [newVideos, setNewVideos] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const [popularCategories, setPopularCategories] = useState([]);
  const clickRef = useRef(null);
  const iframeRef = useRef(null);
  const [isWithinVR, setIsWithinVR] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    apiURL,
    domain,
    settings,

    activeScene,

    intro,
  } = useContext(Context);
  const exvRef = useRef(null);

  const getNewVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_new_watch_videos" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setNewVideos(data);
        console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getNewVideos();
  }, []);

  const getPopularVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_watch_videos" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularVideos();
  }, []);

  const getPopularCategories = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_watch_categories" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularCategories(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularCategories();
  }, []);

  const enablePageScroll = () => {
    document.body.style.overflow = "";
  };

  const disablePageScroll = () => {
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    const handleMouseEnter = () => {
      disablePageScroll();
      setIsWithinVR(true);
    };

    const handleMouseLeave = () => {
      enablePageScroll();
      setIsWithinVR(false);
    };

    const handleFocus = () => {
      disablePageScroll();
      setIsWithinVR(true);
    };

    const handleBlur = () => {
      enablePageScroll();
      setIsWithinVR(false);
    };

    const iframeElement = iframeRef.current;

    // Check if iframe is successfully accessed
    if (iframeElement && iframeElement.contentWindow) {
      iframeElement.addEventListener("mouseenter", handleMouseEnter);
      iframeElement.addEventListener("mouseleave", handleMouseLeave);

      // Attach focus and blur events to the iframe's content window
      iframeElement.contentWindow.addEventListener("focus", handleFocus);
      iframeElement.contentWindow.addEventListener("blur", handleBlur);

      // Touch events for touch devices
      iframeElement.contentWindow.addEventListener("touchstart", handleFocus);
      iframeElement.contentWindow.addEventListener("touchend", handleBlur);

      // Cleanup listeners on component unmount
      return () => {
        iframeElement.removeEventListener("mouseenter", handleMouseEnter);
        iframeElement.removeEventListener("mouseleave", handleMouseLeave);

        if (iframeElement.contentWindow) {
          iframeElement.contentWindow.removeEventListener("focus", handleFocus);
          iframeElement.contentWindow.removeEventListener("blur", handleBlur);
          iframeElement.contentWindow.removeEventListener(
            "touchstart",
            handleFocus
          );
          iframeElement.contentWindow.removeEventListener(
            "touchend",
            handleBlur
          );
        }
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set initial state on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const frame = iframeRef.current;
    if (!frame) return;

    if (isMobile && !intro && activeScene === "video") {
      frame.style = `
        height: 400px;
       
      `;
    } else {
      frame.style = `
        height: 700px;
       
      `;
    }
  }, [isMobile, intro, activeScene]);

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content">
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/bg-page-title.jpg)",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container pt-5"
                  style={{ marginBottom: "150px" }}
                >
                  <h1 className="mb-3 text-capitalize">WATCH TO EARN - VR</h1>
                  {settings && (
                    <h4 className="mb-3 text-capitalize text-white">
                      You will earn a share from the 24% of daily token mint
                      distributed to viewers for watching this video if you meet
                      all of the criteria below:
                    </h4>
                  )}

                  <div
                    className="justify-content-center bg-dark rounded d-flex p-2 border border-info"
                    style={{ width: "100% !important", color: "#fff" }}
                  >
                    <span className="mr-2">
                      <i
                        className="fa fa-check-circle text-success"
                        aria-hidden="true"
                      ></i>{" "}
                      Connected wallet
                    </span>{" "}
                    <span className="mr-2">
                      <i
                        className="fa fa-check-circle text-success"
                        aria-hidden="true"
                      ></i>{" "}
                      Have not watched the video before
                    </span>
                    <span className="mr-2">
                      <i
                        className="fa fa-check-circle text-success"
                        aria-hidden="true"
                      ></i>{" "}
                      It's not your video
                    </span>
                  </div>
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-custom"
                      data-toggle="modal"
                      data-target="#intro"
                      onClick={() => playExplainer(exvRef)}
                    >
                      <i className="fa fa-play" aria-hidden="true"></i> SEE HOW
                      IT WORKS
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="site-content layout-1">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <main className="col-12">
                    <div className="wrap-main-content">
                      <div className="bl-video-detail">
                        <div className="player-video">
                          <div className="bg-video">
                            <div
                              className="video-containera"
                              style={{ overflow: "hidden" }}
                            >
                              <iframe
                                key={`${isMobile}-${intro}`}
                                ref={iframeRef}
                                id="vr-frame"
                                src="/vr-full"
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>

            <div className="container-fluid px-lg-5 px-md-5 px-sm-1">
              <div className="bp-element bp-element-st-list-videos vblog-layout-slider-1">
                <div className="wrap-element">
                  <div className="heading-post">
                    <h2>HOW IT WORKS:</h2>
                    <ul className="stylish-list pl-3">
                      <li>
                        To earn viewers' rewards (distributed daily), make sure
                        you meet all of the criteria stated at the top of this
                        page.
                      </li>
                      <li>
                        There are 2 ways to watch a video. The first is to press
                        the ENTER VR button to enter a VR scene where you can
                        select any video category to watch the videos in that
                        category. The second is to click on a video directly
                        from the intro screen to start watching.
                      </li>
                      <li>
                        When in the VR scene or watching a video, you can enter
                        full screen or VR mode by using the toggle buttons.
                      </li>
                      <li>
                        At the end of each video your views will be recorded if
                        you meet all of the criteria and at the end of the day,
                        you will receive your reward share for the day.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/*WATCH modal*/}
      <div
        className="modal fade"
        id="intro"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="intro"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleStopAndReset(exvRef)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <video
                ref={exvRef}
                controls
                width="100%"
                // height="660px"

                onError={(e) => console.error("Error loading video:", e)}
                className="mt-3"
              >
                <source
                  src={domain + "uploads/explainer/watch2earn.mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WatchVR;
