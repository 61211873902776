import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  shortenAddress,
  handleStopAndReset,
  playExplainer,
} from "../../utils/helpers";
import axios from "axios";

import "./fan.css";

import Pagination from "../../components/Pagination";

function Creators() {
  const [creators, setCreators] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 1,
    total_records: 0,
  });
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("all");
  const exvRef = useRef(null);

  const { apiURL, domain, setLoading } = useContext(Context);

  useEffect(() => {
    if (query.length > 0) {
      const fetchData = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("query", query);
        formData.append("type", type);

        formData.append("action", "search_creators");
        try {
          const response = await axios.post(apiURL, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          setResults(response.data);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
        setLoading(false);
      };

      const debounceTimeout = setTimeout(() => {
        fetchData();
      }, 300); // Adjust the debounce delay as needed

      return () => clearTimeout(debounceTimeout);
    } else {
      setResults([]);
    }
  }, [query]);

  const fetchCreators = async (page = 1, limit = 30) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("page", page);
    formData.append("limit", limit);

    formData.append("action", "get_creators");
    if (type !== "all") {
      formData.append("filter", type);
    }
    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.data.error) {
        setCreators([]);
      } else {
        setCreators(response.data.records);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCreators(1);
  }, [type]);

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div
          id="main-content"
          style={{ background: "#1e1e1e" }}
          className="pt-5"
        >
          <div className="content-area">
            <div className="site-content layout-1">
              <div className="container-fluid">
                <div className="row text-center my-5">
                  <div className="col-12">
                    <h2 className="display-4">BROWSE CREATORS</h2>
                    <button
                      className="btn btn-custom"
                      data-toggle="modal"
                      data-target="#intro"
                      onClick={() => playExplainer(exvRef)}
                    >
                      <i className="fa fa-play" aria-hidden="true"></i> SEE HOW
                      IT WORKS
                    </button>
                  </div>
                  <div className="col-8 my-3">
                    <label>Username or wallet</label>
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search creators by username or wallet"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    {isLoading && <p>Loading...</p>}
                    <ul>
                      {results.length > 0 &&
                        results.map((user, index) => (
                          <li key={index} className="my-2 search-hover">
                            <a
                              href={`profile?user=${
                                !user.username ? user.wallet : user.username
                              }`}
                              style={{ color: "#fff" }}
                              target="_blank"
                            >
                              <div className="text-left ml-5">
                                <img
                                  src={user.profile_photo}
                                  alt="Profile"
                                  width="50"
                                  className="mr-2"
                                  style={{ borderRadius: "50%" }}
                                />
                                {!user.username
                                  ? shortenAddress(user.wallet)
                                  : user.username}{" "}
                                {user.verified === "yes" && (
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="col-4 my-3">
                    <label> Creator Type</label>
                    <select onChange={(e) => setType(e.target.value)}>
                      <option value="all" selected>
                        All creators
                      </option>
                      <option value="real">Real creators</option>
                      <option value="ai">Ai creators</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  {creators.length > 0 &&
                    creators.map((c, i) => (
                      <div className="col-md-4">
                        <a
                          href={`profile?user=${
                            !c.username ? c.wallet : c.username
                          }`}
                          style={{ color: "#fff" }}
                          target="_blank"
                        >
                          <div className="profile-card">
                            <img
                              src={domain + c.cover_photo}
                              className="cover-image"
                              alt="Cover Image"
                            />

                            <img
                              src={domain + c.profile_photo}
                              className="avatar"
                              alt="Avatar"
                            />

                            <div className="profile-details">
                              <h5 className="text-white">
                                {!c.username
                                  ? shortenAddress(c.wallet)
                                  : c.username}{" "}
                                {c.verified === "yes" && (
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  />
                                )}
                              </h5>

                              <div className="d-flex">
                                <span className="p-2">
                                  {" "}
                                  {c.posts_count}{" "}
                                  <i className="fa fa-th" aria-hidden="true" />
                                </span>
                                <span className="p-2">
                                  {" "}
                                  {c.fans_count}{" "}
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
                {creators.length > 0 && (
                  <Pagination
                    currentPage={pagination.current_page}
                    totalPages={pagination.total_pages}
                    onPageChange={(page) => fetchCreators(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
      {/*WATCH modal*/}
      <div
        className="modal fade"
        id="intro"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="intro"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleStopAndReset(exvRef)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <video
                ref={exvRef}
                controls
                width="100%"
                // height="660px"

                onError={(e) => console.error("Error loading video:", e)}
                className="mt-3"
              >
                <source
                  src={domain + "uploads/explainer/just-fans.mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Creators;
