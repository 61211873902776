import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { NftContract } from "../../utils/readContract";
import nftABI from "../../utils/nft.json";

import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  JsonRpcProvider,
} from "ethers";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  showAlert,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
const nftContract = "0xEa3d89683BEEb93D600Ba42128f409dA899DfFe5";

function PornFace() {
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [symbol, setSymbol] = useState("");

  const sidebarRef = useRef(null);

  const [ratio, setRatio] = useState("1:1");
  const [hideSave, setHideSave] = useState(false);
  const [imgStyle, setImgStyle] = useState("photorealism");
  const [data, setData] = useState({});
  const [dbImages, setDbImages] = useState([]);
  const [dbImage, setDbImage] = useState({});
  const [supply, setSupply] = useState("");
  const [baseUrl, setBaseUrl] = useState("");

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    loading,
    setLoading,
    settings,
    user,
    debitUser,
    domain,
  } = useContext(Context);

  const getSupply = async () => {
    try {
      const contract = await NftContract();

      const res = await contract.totalSupply();
      console.log("SSS", res.toString());

      setSupply(res.toString());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSupply();
  }, []);

  const getName = async () => {
    try {
      const contract = await NftContract();

      const res = await contract.name();
      // console.log("SSS", res.toString());

      setName(res.toString());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getName();
  }, []);

  const getSymbol = async () => {
    try {
      const contract = await NftContract();

      const res = await contract.symbol();
      //  console.log("SSS", res.toString());

      setSymbol(res.toString());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSymbol();
  }, []);

  const getBaseUrl = async () => {
    try {
      const contract = await NftContract();

      const res = await contract.baseURI();
      console.log("SSS", res.toString());

      setBaseUrl(res.toString());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBaseUrl();
  }, []);

  const getUserImages = async (wallet) => {
    // if (!wallet || wallet == null) return;
    try {
      const details = {
        action: "get_generated_images",
        wallet: wallet,
      };

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(details),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        refresh();
        console.error("Errors:", data.error);
      } else {
        setDbImages(data);
        console.log("IMAGES:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getUserImages(address);
  }, [address, isConnected]);

  const refresh = () => {
    setData({});
    setPrompt("");
    setDbImage({});
    setHideSave(false);
    // setDbImages({});
    // getUserImages(address);
  };

  const generateImage = async () => {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (+user.credits < +settings.porn_face_fee) {
      showAlert({
        title: "Error!",
        text: "Insufficient v18plus for model creation. Please buy or earn some first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!prompt || !imgStyle || !ratio) {
      showAlert({
        title: "Error!",
        text: "One or more required fields are missing.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const raw = JSON.stringify({
      prompt: prompt,
      style: imgStyle,
      aspect_ratio: ratio,
      output_format: "png",
      response_format: "b64",
    });
    setLoading(true);
    setDbImage({});
    try {
      const response = await fetch(
        "https://api.getimg.ai/v1/essential-v2/text-to-image",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer key-vFPcIvWuVnAH1vvMbq2jXzf5CD6xclvWMHYqgV8MK5BsbqJFsJFmz09fF6jf3AkbtmPWX07rbqzUMfqN2Q1V6HHtj7NWLIK",
          },
          body: raw,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("GEN", data);
      if (data.error) {
        setLoading(false);
        showAlert({
          title: "Error!",
          text: data.error.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (data.image) {
        const d = await debitUser(settings.porn_face_fee);
        if (d) {
          //data.output = data.url;
          setData(data);
          setHideSave(true);
          //  console.log("GEN MOD", data);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const pollForResult = async (url) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("POLL RESULT", data);
      if (data.status === "processing") {
        setTimeout(() => pollForResult(url), 3000);
      } else if (data.status === "success") {
        const d = await debitUser(settings.porn_face_fee);
        if (d) {
          setData(data);
          setHideSave(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const saveImageToServer = async () => {
    if (!data.image) {
      showAlert({
        title: "Error!",
        text: "No NFT image found.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const metadata = {
      name: `${name} #${+supply + 1}`,
      symbol: symbol,
      description:
        "A unique piece of adult art created with Ai using text prompts on the ai18plus platform.",
      image: `${domain}uploads/generated/images/${+supply + 1}.png`,
      edition: +supply + 1,
      external_url: domain,
    };

    const contentType = "image/png"; // Adjust this if needed
    const imageBlob = base64ToBlob(data.image, contentType);
    console.log("Image Blob:", imageBlob);
    console.log("Blob Size:", imageBlob.size);
    console.log("Blob Type:", imageBlob.type);

    const metadataBlob = new Blob([JSON.stringify(metadata)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("prompt", prompt || "None");
    formData.append("wallet", address);
    formData.append("nft_id", +supply + 1);
    formData.append("action", "save_generated_images");
    formData.append("image", imageBlob, `${+supply + 1}.png`);
    formData.append("metadata", metadataBlob, `${+supply + 1}.json`);

    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const datas = response.data;
      console.log("DATAS:", datas);

      if (datas.success) {
        await getUserImages(address);
        await getSupply();
        setHideSave(false);
        showAlert({
          title: "Success!",
          text: datas.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
      } else {
        showAlert({
          title: "Error!",
          text: datas.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
        console.log("Error:", datas.message);
      }
    } catch (error) {
      showAlert({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  async function mint() {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (Object.keys(data).length === 0 || !data.image) {
      showAlert({
        title: "Error!",
        text: "No images found.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    setLoading(true);
    try {
      let signer;
      let contract;

      const provider = new BrowserProvider(walletProvider);
      signer = await provider.getSigner();
      contract = new Contract(nftContract, nftABI, signer);

      const data = await contract.mint();
      console.log("MINT", data);
      async function PendingApprove() {
        try {
          const provider = new BrowserProvider(walletProvider);
          const result = await provider.getTransactionReceipt(data.hash);
          if (result === null) {
            setTimeout(() => {
              PendingApprove();
            }, 2000);
          } else if (result !== null) {
            await saveImageToServer();
            // await getSupply();
          }
        } catch (error) {
          setLoading(false);
        }
      }

      setTimeout(() => {
        PendingApprove();
      }, 2000);
    } catch (error) {
      console.log("MINT ERROR", error);
      setLoading(false);

      showAlert({
        title: "ERROR!",
        text: "There was an error minting your NFT.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    //  setLoading(false);
  }
  /*
  useEffect(() => {
    // jQuery for toggling the sidebar
    $("#toggleSidebar").on("click", function () {
      $("#sidebar").toggleClass("show");
    });

    $("#closeSidebar").on("click", function () {
      $("#sidebar").toggleClass("show");
    });

    // Cleanup event listeners on component unmount
    return () => {
      $("#toggleSidebar").off("click");
      $("#closeSidebar").off("click");
    };
  }, []);
  */

  const handleToggle = () => {
    if (sidebarRef.current) {
      sidebarRef.current.classList.toggle("show");
    }
  };

  const handleSetDbImage = (d) => {
    refresh();
    setDbImage(d);
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/nft-bg4.jpeg)",
                  backgroundAttachment: "fixed",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                      <h1 style={{ fontWeight: 700 }}>YOUR PORN FACE</h1>
                      <div className="description" style={{ color: "#fff" }}>
                        Now it's easy to create your Ai fantasy images
                        (including adult content). Just type in a prompt,
                        describing what you want in the field below and our Ai
                        model will create it. You can also mint your model as an
                        NFT on the blockchain and list it for sale on our NFT
                        marketplace or anywhere.
                      </div>
                    </div>
                    <div className="col-md-6">
                      <video
                        controls
                        width="100%"
                        // height="660px"

                        onError={(e) =>
                          console.error("Error loading video:", e)
                        }
                        className="mt-3"
                      >
                        <source
                          src={domain + "uploads/explainer/porn-face.mp4"}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isConnected && (
              <div className="site-content layout-1">
                <div className="container-fluid">
                  <div className="d-flex">
                    <div className="sidebar" id="sidebar" ref={sidebarRef}>
                      <div className="text-right">
                        <button
                          className="btn btn-danger d-md-none mb-3"
                          onClick={handleToggle}
                        >
                          <i
                            style={{ fontSize: "30px" }}
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="list-group list-group-flush">
                        <h3 className="p-4 text-capitalize text-white">
                          Your {symbol} models
                        </h3>
                        {Object.keys(dbImages).length > 0 ? (
                          dbImages.map((d, i) => {
                            // const imagePaths = JSON.parse(d.image_paths); // Parse the image_paths string
                            return (
                              <button
                                key={i}
                                className="list-group-item list-group-item-action"
                                onClick={() => handleSetDbImage(d)}
                              >
                                <img
                                  src={domain + d.image_paths}
                                  alt="Avatar"
                                />
                                <span>
                                  <b>
                                    {symbol} #{d.id}
                                  </b>{" "}
                                  {" | "}Minted on:{" "}
                                  {formatLocalTime(d.add_date)}
                                </span>
                              </button>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <img
                              src="assets/images/fly.gif"
                              height={100}
                              alt="No images"
                            />
                            <p>No Ai images here yet...</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="main-content flex-grow-1">
                      <button
                        className="btn btn-danger d-md-none mb-3"
                        onClick={handleToggle}
                      >
                        Show previous
                      </button>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="card bg-dark">
                              {Object.keys(data).length === 0 &&
                                Object.keys(dbImage).length === 0 && (
                                  <img
                                    src="assets/images/porn-prev.png"
                                    className="card-img-top"
                                    alt="Generated Image"
                                    id="imagePreview"
                                    height={300}
                                  />
                                )}
                              {Object.keys(dbImage).length === 0 &&
                                Object.keys(data).length > 0 && (
                                  <div className="row">
                                    {/*data.map((g, i) => (
                                      <div key={i} className="col-sm-12 my-2">
                                        <img
                                          src={g.url}
                                          className="card-img-top"
                                          alt="Generated Image"
                                          id={`imagePreview-${i}`}
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    ))*/}
                                    <div className="col-sm-12 my-2">
                                      <img
                                        src={`data:image/png;base64,${data.image}`}
                                        className="card-img-top"
                                        alt="Generated Image"
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              {Object.keys(dbImage).length > 0 && (
                                <div className="row">
                                  <div className="col-sm-12 my-2">
                                    <img
                                      src={domain + dbImage.image_paths}
                                      className="card-img-top"
                                      alt="Generated Image"
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                  {dbImage.prompt !== "None" && (
                                    <div className="col-sm-12 my-2 mx-2">
                                      {dbImage.prompt}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="card-body">
                                <div className="text-right d-flex mb-5">
                                  {hideSave && (
                                    <div className="mr-3">
                                      <button
                                        className="btn btn-success mb-3"
                                        id="downloadButton"
                                        onClick={mint}
                                      >
                                        Mint as NFT
                                      </button>
                                    </div>
                                  )}

                                  <div>
                                    <button
                                      className="btn btn-danger mb-3"
                                      id="downloadButton"
                                      onClick={refresh}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                                <label>Prompt</label>
                                <textarea
                                  style={{ background: "#000" }}
                                  className="form-control"
                                  rows={6}
                                  placeholder="Type a detailed description of the image you need."
                                  id="promptText"
                                  value={prompt}
                                  onInput={(e) => setPrompt(e.target.value)}
                                  required
                                />

                                <div className="row my-3">
                                  <div className="col-md-6">
                                    <label>Aspect Ratio </label>
                                    <select
                                      onChange={(e) => setRatio(e.target.value)}
                                    >
                                      <option disabled>
                                        Chose aspect ratio
                                      </option>
                                      <option value="1:1" selected>
                                        1:1
                                      </option>
                                      <option value="4:5">4:5</option>
                                      <option value="5:4">5:4</option>
                                      <option value="2:3">2:3</option>
                                      <option value="3:2">3:2</option>
                                      <option value="4:7">4:7</option>
                                      <option value="7:4">7:4</option>
                                    </select>
                                  </div>
                                  <div className="col-md-6">
                                    <label>Image style</label>
                                    <select
                                      onChange={(e) =>
                                        setImgStyle(e.target.value)
                                      }
                                    >
                                      <option value="photorealism" selected>
                                        Photorealism
                                      </option>
                                      <option value="art">Art</option>
                                      <option value="anime">Anime</option>
                                    </select>
                                  </div>
                                </div>

                                <div
                                  className="w-100 text-center p-3 my-3 rounded text-white"
                                  style={{
                                    backgroundColor: "#111",
                                    border: "1px red solid",
                                  }}
                                >
                                  <i
                                    className="text-warning fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  You will be charged{" "}
                                  <strong>
                                    {formatNumber(settings.porn_face_fee)}
                                  </strong>{" "}
                                  v18plus tokens for this service.
                                </div>

                                <div className="text-center">
                                  <button
                                    className="btn btn-danger mt-3"
                                    id="generateButton"
                                    onClick={generateImage}
                                  >
                                    Generate Image
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isConnected && (
              <div className="alert-warning p-3 my-3 rounded">
                <i className="fa fa-info-circle" aria-hidden="true"></i> Please
                connect your wallet to use this app.
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}
export default PornFace;
