import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Swap from "../../components/face-swap";

import { Context } from "../../context/contex";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 showAlert,
} from "../../utils/helpers";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function FaceSwap() {
 const [data, setData] = useState({});
 const [dbImages, setDbImages] = useState([]);
 const [dbImage, setDbImage] = useState("");
 const [selectedImage, setSelectedImage] = useState(null);
 const [swapPhotoPreview, setSwapPhotoPreview] = useState(null);
 const [swapPhoto, setSwapPhoto] = useState(null);
 const [swapVideo, setSwapVideo] = useState(null);
 const [swapVideoPreview, setSwapVideoPreview] = useState(null);
 const [theVideo, setTheVideo] = useState();

 const [swapPhotoPath, setSwapPhotoPath] = useState(null);
 const [swapVideoPath, setSwapVideoPath] = useState(null);
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [selectedFrame, setSelectedFrame] = useState("");
 const [swapAll, setSwapAll] = useState("yes");

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  loading,
  setLoading,
  settings,
  user,
  debitUser,
  domain,
 } = useContext(Context);

 const handleImageClick = (image, index) => {
  setSelectedImage(index); // Set the selected image index
  setDbImage(domain + image);
 };

 const handleSwapPhoto = async (e) => {
  const file = e.target.files[0];
  if (file) {
   setLoading(true);
   await handleSwapUpload(file, false);
   setSwapPhoto(file);
   setSwapPhotoPreview(URL.createObjectURL(file));
   setLoading(false);
  }
 };

 const handleVideoChange = async (e) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith("video/")) {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";
   videoElement.onloadedmetadata = async function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;
    if (duration > 59) {
     setSwapVideoPreview(null);
     return showAlert({
      title: "Error!",
      text: "Video must be less than 1 minute.",
      icon: "error",
      confirmButtonText: "Ok",
     });
    } else {
     setLoading(true);
     await handleSwapUpload(file, true);
     setSwapVideoPreview(URL.createObjectURL(file));
     const video = videoRef.current;
     video.src = URL.createObjectURL(file);
     video.onloadeddata = () => {
      extractFrames(video);
     };
     setLoading(false);
    }
   };
   videoElement.src = URL.createObjectURL(file);
  }
 };

 const extractFrames = async (video) => {
  // const video = videoRef.current;
  if (!video) return;

  const duration = video.duration;
  if (!duration || isNaN(duration)) {
   console.error("Failed to get video duration");
   setLoading(false);
   return;
  }

  setLoading(true);
  const interval = duration / 10; // Adjust for more or fewer frames
  const framesArray = [];

  // Function to seek the video and capture a frame
  const captureFrame = (time) => {
   return new Promise((resolve) => {
    video.currentTime = time;
    video.onseeked = () => {
     const canvas = canvasRef.current;
     const context = canvas.getContext("2d");
     context.drawImage(video, 0, 0, canvas.width, canvas.height);
     const frameData = canvas.toDataURL();
     resolve(frameData); // Resolve the promise with the captured frame
    };
   });
  };

  // Capture frames at each interval
  for (let i = 0; i <= 10; i++) {
   const currentTime = i * interval;
   if (isFinite(currentTime)) {
    try {
     const frame = await captureFrame(currentTime); // Wait for the frame capture
     framesArray.push(frame);
    } catch (error) {
     console.error("Error capturing frame at time:", currentTime, error);
    }
   } else {
    console.error("Calculated currentTime is not finite", currentTime);
   }
  }

  setFrames(framesArray);
  // setFrames(null);
  setSelectedFrame("");
  setLoading(false);
 };

 const getUserImages = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_generated_images",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setDbImages(data);
    //  console.log("IMAGES:", m);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserImages(address);
 }, [address, isConnected]);

 const refresh = () => {
  setData({});
  setDbImage("");
  setDbImages([]);
  // getUserImages(address);
 };

 const handleSwapUpload = async (swapPhoto, isVideo) => {
  if (!swapPhoto) {
   showAlert({
    title: "Error!",
    text: "Please select an image to upload",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const formData = new FormData();
  formData.append("image", swapPhoto);
  formData.append("action", "upload_swap_face");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   if (response.data.path) {
    if (!isVideo) {
     setSwapPhotoPath(response.data.path);
    } else {
     setSwapVideoPath(response.data.path);
    }
   } else {
    showAlert({
     title: "Error!",
     text: response.data.error,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error uploading the file:", error);
   alert("Error uploading the file.");
  }
 };

 const swapVideoFace = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapVideoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target video or  image is missing. Make sure to select these first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   init_video: swapVideoPath,
   init_image: dbImage,
   watermark: false,
   webhook: null,
   track_id: null,
  });
  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/single_video_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkVideoAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setTheVideo(data.output[0]);
    }
    // setData(data);
    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const swapVideoFaceSpecific = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapVideoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target video or  image is missing. Make sure to select these first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!selectedFrame) {
   showAlert({
    title: "Error!",
    text: "Please select a frame from the video.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   init_video: swapVideoPath,
   init_image: dbImage,
   reference_frame_number: selectedFrame,
   webhook: null,
   track_id: null,
   watermark: false,
  });

  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/specific_video_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkVideoAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setTheVideo(data.output[0]);
    }
    // setData(data);
    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const handleVideoSwap = () => {
  setData({});
  setTheVideo("");
  if (swapAll === "yes") {
   swapVideoFace();
  } else {
   swapVideoFaceSpecific();
  }
 };

 const pollForResult = async (url) => {
  try {
   const response = await fetch(url, {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("POLL RESULT", data);
   if (data.status === "processing") {
    setTimeout(() => pollForResult(url), 5000);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setData(data);
    }
    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const saveVideo = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!theVideo) {
   showAlert({
    title: "Error!",
    text: "Video not found.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  setLoading(true);

  const formData = new FormData();

  formData.append("wallet", address);
  formData.append("video", theVideo);

  formData.append("action", "save_ai_video");

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    body: formData,
   });

   const datas = await response.json();

   if (datas.success) {
    showAlert({
     title: "Success!",
     text: datas.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    showAlert({
     title: "Error!",
     text: datas.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
    console.log("Error:", datas.message);
   }
  } catch (error) {
   showAlert({
    title: "Error!",
    text: "An unexpected error occurred. Please try again later.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.error("Error:", error);
  } finally {
   setLoading(false);
  }
 };

 const swapFace = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapPhotoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target image is missing. Make sure to select these photos first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   target_image: dbImage,
   init_image: swapPhotoPath,
   watermark: false,
   webhook: null,
   track_id: null,
  });
  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/multiple_face_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkImageAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setData(data);
    }

    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const checkImageAvailability = async (data) => {
  setLoading(true);
  const interval = 5000;

  const poll = setInterval(async () => {
   try {
    const response = await axios.get(data.future_links[0], {
     validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
     },
    });

    if (response.status === 200) {
     clearInterval(poll);
     const d = await debitUser(settings.swap_face_fee);
     if (d) {
      data.output[0] = data.future_links[0];
      setData(data);
      console.log("SWAP MOD", data);
     }
     setLoading(false);
    } else {
     console.log("Image not available yet. Retrying...");
    }
   } catch (error) {
    console.log("Error fetching the image:", error);
   }
  }, interval);
 };

 const checkVideoAvailability = async (data) => {
  setLoading(true);
  const interval = 5000;

  const poll = setInterval(async () => {
   try {
    const response = await axios.get(data.future_links[0], {
     validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
     },
    });

    if (response.status === 200) {
     clearInterval(poll);
     const d = await debitUser(settings.swap_face_fee);
     if (d) {
      data.output[0] = data.future_links[0];
      setTheVideo(data.future_links[0]);
      console.log("SWAP MOD", data);
     }
     setLoading(false);
    } else {
     console.log("Video not available yet. Retrying...");
    }
   } catch (error) {
    console.log("Error fetching the image:", error);
   }
  }, interval);
 };

 const testVid = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   model_id: "zeroscope",
   prompt: "full HD video of a woman with blonde hair touching her big breasts.",
   negative_prompt: "low quality",
   height: 320,
   width: 576,
   num_frames: 20,
   fps: 2,
   num_inference_steps: 20,
   guidance_scale: 7,
   upscale_height: 640,
   upscale_width: 1024,
   upscale_strength: 0.6,
   upscale_guidance_scale: 12,
   upscale_num_inference_steps: 20,
   output_type: "mp4",
   webhook: null,
   track_id: null,
  });

  const requestOptions = {
   method: "POST",
   headers: myHeaders,
   body: raw,
   redirect: "follow",
  };

  fetch("https://modelslab.com/api/v6/video/text2video", requestOptions)
   .then((response) => response.json())
   .then((result) => console.log(result))
   .catch((error) => console.log("error", error));
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/nft-bg4.jpeg)",
         backgroundAttachment: "fixed",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <div className="row d-flex align-items-center">
          <div className="col-md-6">
           <h1 style={{ fontWeight: 700 }}>LET'S FACE-SWAP</h1>
           <div className="description" style={{ color: "#fff" }}>
            <b>Tips:</b>
            <br />
            To get best on your Ai face swap, please ensure the quality of the video you want to
            reface has a high resolution with clear faces.
           </div>
          </div>
          <div className="col-md-6">
           <video
            controls
            width="100%"
            // height="660px"

            onError={(e) => console.error("Error loading video:", e)}
            className="mt-3"
           >
            <source src={domain + "uploads/explainer/face-swap.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
           </video>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <Swap />
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>

   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default FaceSwap;
