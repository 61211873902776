import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import axios from "axios";
import { showAlert } from "../utils/helpers";
import "./styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function Contact() {
 const {
  address,

  apiURL,

  setLoading,
 } = useContext(Context);

 const [Subject, setSubject] = useState("");
 const [email, setEmail] = useState("");
 const [wallet, setWallet] = useState("");
 const [message, setMessage] = useState("");
 const [sent, setSent] = useState(false);

 const [coverPhoto, setCoverPhoto] = useState(null);
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [uploadVideoPreview, setUploadVideoPreview] = useState(null);

 const [uploadProgress, setUploadProgress] = useState(0);
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [frame, setFrame] = useState(null);

 const [selectedFrame, setSelectedFrame] = useState("");

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setFrame(null);
   setSelectedFrame("");
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  if ((!wallet && !address) || !email || !Subject || !message) {
   return showAlert({
    title: "Error!",
    text: "Please fill all required fields.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  setLoading(true);
  const formData = new FormData();
  formData.append("wallet", address ? address : wallet);
  formData.append("email", email);
  formData.append("subject", Subject);
  formData.append("message", message);
  if (coverPhoto) {
   formData.append("photo", coverPhoto, "attachment.png");
  }

  formData.append("action", "new_contact_msg");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });
   console.log("Form", response);
   if (response.data.success) {
    setSent(true);
   } else {
    showAlert({
     title: "ERROR!",
     text: response.data.message || "Something went wrong!",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   // alert(`Error: ${error.message}`);
   // Handle network error
  }
  setLoading(false);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/bg-page-title.jpg)",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1>CONTACT US</h1>
         <div className="description">
          Fill the form below to get in touch with our support team. We typically reply in 5
          minutes.
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {sent ? (
          <div className="alert-success p-3 my-3 rounded text-center">
           <div>
            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "100px" }}></i>
           </div>
           <h3>Message Sent</h3>
           <div>
            Your message has been sent. One of our support staff will be in touch with you via email
            shortly.
           </div>
          </div>
         ) : (
          <form onSubmit={handleSubmit}>
           <div className="row">
            <div className="col-md-6">
             <div className="form-group">
              <label htmlFor="videoCategory">Your registered wallet</label>
              <input
               type="text"
               className="form-control"
               placeholder="User wallet"
               onChange={(e) => setWallet(e.target.value)}
               value={address || wallet}
               required
              />
             </div>
            </div>
            <div className="col-md-6">
             <div className="form-group">
              <label htmlFor="videoTitle">Contact email</label>
              <input
               type="text"
               className="form-control"
               placeholder="Enter email"
               onChange={(e) => setEmail(e.target.value)}
               value={email}
               required
              />
             </div>
            </div>

            <div className="col-md-12">
             <div className="form-group">
              <label htmlFor="videoTitle">Subject</label>
              <input
               type="text"
               className="form-control"
               placeholder="Enter subject"
               onChange={(e) => setSubject(e.target.value)}
               value={Subject}
               required
              />
             </div>
            </div>

            <div className="col-md-12">
             <div className="form-group">
              <label htmlFor="videoDescription">Your message</label>
              <textarea
               className="form-control"
               style={{ height: "120px" }}
               placeholder="Type your message here."
               required
               onChange={(e) => setMessage(e.target.value)}
              />
             </div>
            </div>

            <div className="col-md-12 text-center">
             <div className="form-group">
              <label htmlFor="coverPhoto">
               <img
                src={coverPhotoPreview || "./assets/images/ph-image.png"}
                height={200}
                alt="cover placeholder"
               />
              </label>
              <div className="custom-file">
               <input
                type="file"
                className="custom-file-input"
                id="coverPhoto"
                accept="image/*"
                onChange={handleCoverPhotoChange}
               />
               <label className="custom-file-label" htmlFor="coverPhoto">
                Click to optionally attach any relevant screenshot or photo
               </label>
              </div>
             </div>
            </div>

            {uploadProgress > 0 && (
             <div className="col-md-12 my-4">
              <div className="progress">
               <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
               >
                {uploadProgress}%
               </div>
              </div>
             </div>
            )}

            <div className="col-md-12">
             <button type="submit" className="btn btn-custom btn-block">
              Submit
             </button>
            </div>
           </div>
          </form>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Contact;
