import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import $ from "jquery";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 getPercentage,
 showAlert,
} from "../../utils/helpers";
import "../styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function CreateNFT() {
 const [video, setVideo] = useState({});

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  domain,
  setLoading,
  tokenPrice,
 } = useContext(Context);

 const [name, setName] = useState("");
 const [symbol, setSymbol] = useState("");
 const [coverPhoto, setCoverPhoto] = useState(null);
 const [supply, setSupply] = useState("");
 const [dbImages, setDbImages] = useState([]);
 const [dbImage, setDbImage] = useState("");
 const [selectedImage, setSelectedImage] = useState(null);
 const [nft, setNft] = useState([]);
 const [price, setPrice] = useState("");
 const [isEdit, setIsEdit] = useState(false);

 const handleImageClick = (image, index) => {
  setSelectedImage(index);
  setCoverPhoto(image);
  setDbImage(domain + image);
 };

 const getUserImages = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_generated_images",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    // refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setDbImages(data);
    console.log("IMAGES:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserImages(address);
 }, [address, isConnected]);

 const getNFT = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_profile_nft",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    // refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setNft(data);
    console.log("NFT:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getNFT(address);
 }, [address, isConnected]);

 useEffect(() => {
  if (nft.length > 0) {
   setName(nft[0].name);
   setSymbol(nft[0].symbol);
   // setSupply(nft[0].supply);
   setPrice(nft[0].price);
   setCoverPhoto(nft[0].image);
   setDbImage(domain + nft[0].image);
   setIsEdit(true);
  }
 }, [nft]);

 const createNft = async (e) => {
  e.preventDefault();

  // Check if wallet is connected
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!name || !symbol || !supply || !price) {
   return showAlert({
    title: "Error!",
    text: "Please fill all details",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (isNaN(supply) || isNaN(price)) {
   return showAlert({
    title: "Error!",
    text: "Price or supply is invalid.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (name.length > 50 || symbol.length > 4) {
   return showAlert({
    title: "Error!",
    text:
     "Name or symbol too long. Name should be 50 characters max while symbol should be 4 characters max.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!coverPhoto) {
   return showAlert({
    title: "Error!",
    text: "No model selected.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+supply > 1000) {
   return showAlert({
    title: "Error!",
    text: `You cannot mint more than 1000 NFTs.`,
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (nft.length > 0 && +nft[0].supply + +supply > 1000) {
   return showAlert({
    title: "Error!",
    text: `You cannot mint more than 1000 NFTs. You have minted ${nft[0].supply} NFTs so far.`,
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  // Construct form data
  const formData = new FormData();
  formData.append("wallet", address);
  formData.append("name", name);
  formData.append("symbol", symbol);
  formData.append("supply", supply);
  formData.append("price", price);
  formData.append("image_link", coverPhoto);

  formData.append("action", "create_profile_nft");
  setLoading(true);
  try {
   // Debug FormData contents
   console.log("FormData:", [...formData]);

   // Make the API call
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   // Handle response
   if (response.data.success) {
    getNFT(address);
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: `url(${user.cover_photo || domain + "assets/images/cover.png"})`,
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1 style={{ fontWeight: "700" }}>TURN YOUR PROFILE TO AN NFT</h1>
         <div className="description">
          Get started with minting a piece of you as an NFT and earn by listing your NFTs for sale
          on <b>Ai18plus NFT marketplace.</b> Fans who believe in your profiles's potentials can buy
          your NFT and get a share from your subscription earnings.
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect your
           wallet to create NFTs.
          </div>
         ) : (
          <>
           <form onSubmit={createNft}>
            {user.is_creator === "yes" ? (
             <div className="row">
              {isEdit && (
               <div className="col-md-12 my-5 border border-danger alert-warning rounded p-3">
                <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> You
                are editing an already existing NFT. Changes made to supply and price will not
                affect percentage share of fans who already bought the NFT before now.
               </div>
              )}
              <div className="col-md-6">
               <div className="form-group">
                <label>NFT collection name</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="Sexy Tits Club"
                 onChange={(e) => setName(e.target.value)}
                 value={name}
                 required
                 readOnly={isEdit}
                />
               </div>
              </div>
              <div className="col-md-6">
               <div className="form-group">
                <label>NFT symbol</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="STC"
                 onChange={(e) => setSymbol(e.target.value)}
                 value={symbol}
                 required
                 readOnly={isEdit}
                />
               </div>
              </div>
              <div className="col-md-6">
               <div className="form-group">
                <label htmlFor="username">
                 Total supply ({`${nft.length > 0 ? 1000 - nft[0].supply : 1000}`} NFTs max)
                </label>
                <input
                 type="number"
                 className="form-control"
                 placeholder="1 to 1000"
                 onChange={(e) => setSupply(e.target.value)}
                 value={supply}
                 required
                />
                {supply && (
                 <div className="text-danger mt-1">
                  By this supply each NFT equals{" "}
                  {(() => {
                   const result =
                    50 / parseFloat(nft.length > 0 ? +nft[0].supply + +supply : +supply);
                   return result % 1 === 0 ? result : result.toFixed(2);
                  })()}
                  % of your profile.
                 </div>
                )}
               </div>
              </div>
              <div className="col-md-6">
               <div className="form-group">
                <label>Listing price per NFT (in $18plus token)</label>
                <input
                 type="number"
                 className="form-control"
                 placeholder="Eg: 100000000"
                 onChange={(e) => setPrice(e.target.value)}
                 value={price}
                 required
                />
                {price && (
                 <div className="text-danger mt-1">${+price * tokenPrice} USD per NFT</div>
                )}
               </div>
              </div>

              <div className="col-md-12 text-center my-4">
               <h3>
                Choose an image from your Ai models to set as NFT image or{" "}
                <a className="text-danger" href="/porn-face" target="_blank">
                 create a new model
                </a>
               </h3>
              </div>

              <div className="col-md-6 text-center my-5">
               <div
                style={{
                 display: "flex",
                 flexWrap: "wrap",
                 gap: "10px",
                 height: "400px",
                 overflowY: "auto",
                }}
               >
                {dbImages.length > 0 &&
                 dbImages.map((frame, index) => (
                  <img
                   key={index}
                   src={domain + frame.image_paths}
                   alt={`image ${index}`}
                   onClick={() => handleImageClick(frame.image_paths, index)}
                   style={{
                    cursor: "pointer",
                    width: "100px",
                    height: "100px",
                   }}
                   className={`frame-image ${selectedImage === index ? "selected" : ""}`}
                  />
                 ))}
                {dbImages.length === 0 && (
                 <div className="text-center">
                  <img src="assets/images/fly.gif" height={100} alt="No images" />
                  <p>
                   No Ai models here yet...{" "}
                   <a className="text-danger" href="/porn-face" target="_blank">
                    create a new model first
                   </a>
                  </p>
                 </div>
                )}
               </div>
              </div>

              <div className="col-md-6 text-center my-5">
               <img src={dbImage || user.cover_photo} height={400} alt="cover placeholder" />
              </div>

              <div className="col-md-12 text-center my-2">
               <div className=""></div>
               <button type="submit" className="btn btn-custom">
                Create NFT
               </button>
              </div>
             </div>
            ) : (
             <div className="alert-danger p-3 my-3 rounded">
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Sorry, you must
              first{" "}
              <a className="text-primary" href="/become-creator">
               become a creator to create profile NFTs
              </a>
              .
             </div>
            )}
           </form>
          </>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default CreateNFT;
