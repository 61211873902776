import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import axios from "axios";
import { showAlert } from "../utils/helpers";
import "./styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function Frame() {
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    // Exit early when time runs out
    if (timeLeft === 0) {
      return showAlert({
        title: "GREAT NEWS!",
        text: "Watch recorded and you will receive v18plus tokens by the end of the day.",
        icon: "success",
        confirmButtonText: "Sounds good",
      });
    }

    // Set up the countdown interval
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clean up the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Calculate minutes and seconds from timeLeft
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  // Calculate progress percentage
  const progress = ((60 - timeLeft) / 60) * 100;
  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/bg-page-title.jpg)",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <h1>WATCH TO EARN</h1>
                  <div className="description">
                    Earn for watching videos from our partner sites
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="text-center">
                  <img src="/ad1.gif" height={150} />
                </div>

                <div style={{ width: "100%", textAlign: "center" }}>
                  <h1>You will earn v18plus tokens in:</h1>
                  <div style={{ fontSize: "24px", marginBottom: "10px" }}>
                    {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                  </div>

                  {/* Progress bar */}
                  <div
                    style={{
                      width: "100%",
                      height: "30px",
                      background: "#e0e0e0",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: `${progress}%`,
                        height: "100%",
                        background: "#76c7c0",
                        borderRadius: "5px",
                        transition: "width 1s linear",
                      }}
                    ></div>
                  </div>
                </div>

                <div className="form-container">
                  <div style={{ overflowY: "hidden" }}>
                    <iframe
                      src="https://xhamster.com/videos/massage-rooms-small-tits-olivia-sparkle-big-dick-pov-blowjob-and-creampie-xhwCFjc?hideNav=true"
                      className="p-3 my-3 rounded text-center w-100 ext-frame"
                    ></iframe>
                  </div>
                </div>
                <div className="text-center my-4">
                  <img src="/ad2.png" height={150} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default Frame;
