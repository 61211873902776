// src/index.js or src/main.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";

import Contact from "./pages/contact";

import CreateNFT from "./pages/NFT/create-nft";
import ProfileNFT from "./pages/NFT/profile-nft";
import OnChainNFT from "./pages/NFT/onchain-nft";
import WatchHome from "./pages/watch/index";
import WatchVR from "./pages/watch/watch-vr";
import VRFull from "./pages/VRFull";
import Admin from "./pages/admin18";
import WatchCategories from "./pages/watch/categories";
import Category from "./pages/watch/category";
import Profile from "./pages/Profile/profile";
import EditVideo from "./pages/watch/edit-post";
import EditProfile from "./pages/Profile/edit-profile";

import PostVideo from "./pages/watch/post-video";
import PostFanVideo from "./pages/fan/post-fan-video";

import SingleVideo from "./pages/watch/single-video";
import FanVideo from "./pages/fan/fan-video";

import Fans from "./pages/fan/fan-real";
import Creators from "./pages/fan/creators";
import BecomeCreator from "./pages/fan/become-creator";
import PornFace from "./pages/services/porn-face";
import FaceSwap from "./pages/services/face-swap";
import Shop from "./pages/services/shop";
import Frame from "./pages/frame";
import NewSwap from "./pages/services/new-swap";

const App = () => (
 <Router>
  <Routes>
   <Route path="/" element={<Home />} />
   <Route path="contact" element={<Contact />} />

   <Route path="watch" element={<WatchHome />} />
   <Route path="watch-vr" element={<WatchVR />} />
   <Route path="vr-full" element={<VRFull />} />
   <Route path="watch-categories" element={<WatchCategories />} />
   <Route path="categories" element={<Category />} />
   <Route path="profile" element={<Profile />} />
   <Route path="edit-post" element={<EditVideo />} />
   <Route path="shop" element={<Shop />} />

   <Route path="edit-profile" element={<EditProfile />} />

   <Route path="post-video" element={<PostVideo />} />
   <Route path="post-fan-video" element={<PostFanVideo />} />
   <Route path="single-video" element={<SingleVideo />} />
   <Route path="fan-video" element={<FanVideo />} />
   <Route path="fans" element={<Fans />} />
   <Route path="creators" element={<Creators />} />
   <Route path="become-creator" element={<BecomeCreator />} />
   <Route path="create-nft" element={<CreateNFT />} />
   <Route path="profile-nft" element={<ProfileNFT />} />
   <Route path="onchain-nft" element={<OnChainNFT />} />
   <Route path="porn-face" element={<PornFace />} />
   <Route path="face-swap" element={<FaceSwap />} />
   <Route path="admin18" element={<Admin />} />
   <Route path="frame" element={<Frame />} />
   <Route path="new-swap" element={<NewSwap />} />
   <Route path="coin" element={<About />} />
  </Routes>
 </Router>
);

export default App;
